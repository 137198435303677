<template>
  <div class="bottom">
    <div class="bottom-content">
      <img
        class="bottom-content-image"
        src="@/assets/images/logo.svg"
        alt="">
      <h3 class="bottom-content-title">
        Get Started <br>with THE ORCA
      </h3>
    </div>
  </div>
</template>

<script lang="ts">
</script>

<style lang="scss" scoped>
.bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: get-vw(100px) 0 get-vw(114px);
  background: linear-gradient(180deg,#454c50,#161819);
  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: get-vw(50px);
    &-image {
      width: 100%;
    }
    &-title {
      padding-top: get-vw(60px);
      font-size: get-vw(70px);
      color: #fff;
      text-align: center;
      line-height: get-vw(70px);
    }
  }
}
</style>
